import { createDocumentResource, createQueryResource, list } from 'retil';
import { Entry } from '@2024-sophians-web/components/src/types';

export const entry = createDocumentResource<Entry>('entry');
export const entryList = createQueryResource('entryList', {
    for: list(entry),
    load: async () => {
        return [
            {
                id: 1,
                entry_id: 1,
                block: 'A',
                stage: '1',
                aspect: 'portrait',
                name: '伊東 千鶴',
                birthday: "2005/3/12",
                faculty: "総合人間科学部\n社会福祉学科",
                grade: "2年",
                hometown: "東京都",
                theme: "〜見た目と中身の大きなギャップ？〜\nわたしのボランティア・社会貢献活動🤝🏼🌏",
                instagram: "sophians2024_no.1",

                // TODO
                course: "",
                hobby: "",
                twitter: "",

                // コンテストに出場したきっかけ
                interview1: "私は、これまでさまざまなボランティア・社会貢献活動に取り組んできましたが、そのなかで、自分の無力さを感じることがよくありました。自分が手を差し伸べられるのは、そのボランティア先で出会ったごくわずかな人たちに限定されてしまうこと。自分にはまだ社会を変えるほどの大きな力がないということ。このようなもどかしさを常に抱いていました。しかし、私が実現したいのは、世界中に存在する多くの困難を抱えた人々の味方で常にあり続けたいということで、性別・年齢・人種・様々なバッググラウンドに関わらず、すべての人々に日々やさしさが降り注ぐような社会を作りたいです。そのためには、自分が影響力のある人になる必要があると感じ、今回このコンテストに出場させていただきました。",
                // コンテストを通じて伝えたいこと
                interview2: "「ボランティア活動」「社会貢献活動」と聞くとどこか真面目で堅いイメージを抱く人が多いのではないかと思いますが、そんな人たちに向けて、私はそういった活動のやりがいや魅力といったものを発信していきたいです。そして、そんな私の発信を受けて、ボランティア活動や社会貢献活動といったものに少しでも関心を向けてくださる方が増えれば嬉しいです。-「他者のために、他者とともに（上智大学建学理念）」生きることの本質とは何か。その本質を、私なりの表現で皆さんにお伝えしていきます。",
                // アピールポイント
                interview3: "い　いつも\nと　努力惜しまず🏃‍♀️\nう　飢えなき好奇心🔥\nち　力みなぎるエネルギッシュさ💪🏻\nづ　ずーーっと変わらぬモチベーション💡\nる　るんるんっポジティブ！",
                // 所属サークル
                interview4: "・きょうだい児支援サークル連\n・わかたけサークル（障害児支援）\n・MLT子どもプロジェクト（子ども食育支援）\n・ダンスサークル",
                // アルバイト
                interview5: "焼肉屋さん🍖",
                // 上智大学の魅力
                interview6: "人！素敵な人たちがたくさんいます。上智はワンキャンパスなので、大学内で様々な学部学科の人たちに出会えるというのと、外国にルーツのある人たちも多く在籍しているので、そういった多様な出会いが自身にとってとても刺激になっています。「こんな素敵な人がいるんだ」「こういう素敵な考え方があるんだ」という新たな刺激がたくさんあることで、私自身（ありがたいことに）キラキラ楽しい上智大生ライフを送ることができています。BIG LOVE SOPHIAN！🤍",
                // マイブーム
                interview7: "・ひとり家系ラーメン\n上智の近くにある四ツ谷商店さんなどよく利用させてもらっています！\n・ドライヤーで髪を乾かしている時に、youtubeでカラオケ動画を流しながら、爆音でロックを熱唱すること。\n・古着めぐり\nアメリカンヴィンテージが好きです",
                // 将来の夢
                interview8: "周りの人に自分と出会ってよかったと思ってもらえるような人になりたいです。",
                // コンテストへの意気込み
                interview9: "人生で1番頑張った、と言い切れるような4ヶ月間にしたいです。コンテストのために・上智大学のために・社会のために、今の自分には何ができるのかを日々問い続けながら、自分に出せる最大限の力をこのコンテストに注ぐことをお約束します。私の全力を少しでも受け取ってくださいましたら、幸いです。よろしくお願いします！"
            },
            {
                id: 2,
                entry_id: 2,
                block: 'A',
                stage: '1',
                aspect: 'portrait',
                name: '中嶋 未来',
                birthday: "2003/8/22",
                faculty: "外国語学部\n英語学科",
                grade: "3年",
                hometown: "三重県",
                theme: "「和」の魅力",
                instagram: "sophians2024_no.2",

                // TODO
                course: "",
                hobby: "",
                twitter: "",

                // コンテストに出場したきっかけ
                interview1: "上智大学や自身の個性を発信する場として「開かれた特色豊かなコンテスト」に惹かれたからです。",
                // コンテストを通じて伝えたいこと
                interview2: "日本が誇る「和」の魅力を日本だけではなく、国境を越えて世界中の人々に発信していきたいです！",
                // アピールポイント
                interview3: "持ち前の「明るさ」と、\n誰にも負けない「トーク力」です！",
                // 所属サークル
                interview4: "放送研究会\n（フラダンスやフラメンコサークルにも所属していました）",
                // アルバイト
                interview5: "塾講師",
                // 上智大学の魅力
                interview6: "互いを認め合える環境",
                // マイブーム
                interview7: "人間ウォッチング（山手線をぐるぐるしながら人間観察）・\nかき氷巡り（季節関係なく1日に何個も食べに行くことも！）",
                // 将来の夢
                interview8: "「唯一無二のエンターテイナー」を目指してこれからも努めます！",
                // コンテストへの意気込み
                interview9: "頂いた環境に恥じぬよう、「私らしさ」と支えて下さる方々への「感謝」を忘れずに精一杯頑張ります。\n暖かく応援して下さると嬉しいです！"
            },
            {
                id: 3,
                entry_id: 3,
                block: 'A',
                stage: '1',
                aspect: 'landscape',
                name: 'マルセロ 侑希',
                birthday: "2002/9/6",
                faculty: "文学部\n新聞学科",
                grade: "4年",
                hometown: "神奈川県",
                theme: "挑戦",
                instagram: "sophians2024_no.3",

                // TODO
                course: "",
                hobby: "",
                twitter: "",

                // コンテストに出場したきっかけ
                interview1: "私は小さいころからハーフというマイノリティとして悩んできた経験があることから現在はサービスラーニングプロジェクトを立ち上げて日本語支援に携わっています。マイノリティ側への支援の大切さについて発信をしたいと思い、このコンテストへの出場を決意しました。",
                // コンテストを通じて伝えたいこと
                interview2: "日本にはたくさん良いところがあるのに、日本の課題にうまく目を向けられていない、このような現状を変えていきたいです。\n日本の良い面はもちろん、マイノリティ側の理解を深めるコンテンツを発信します！",
                // アピールポイント
                interview3: "行動力",
                // 所属サークル
                interview4: "B-pro",
                // アルバイト
                interview5: "スターバックス、ブライダル",
                // 上智大学の魅力
                interview6: "国際色・個性豊かな人がたくさんいていろんな価値観を持つ人と出会えること！",
                // マイブーム
                interview7: "長距離ドライブ",
                // 将来の夢
                interview8: "影響力のある人",
                // コンテストへの意気込み
                interview9: "これから４か月間精一杯頑張りますので応援よろしくお願いいたします。\nみんなで今年のソフィアンズコンテストを盛り上げましょう！"
            },
            {
                id: 4,
                entry_id: 4,
                block: 'A',
                stage: '1',
                aspect: 'portrait',
                name: '津路 眞子',
                birthday: "2002/8/25",
                faculty: "法学部\n地球環境法学科",
                grade: "3年",
                hometown: "神奈川県",
                theme: "「狩猟」を通じて地球環境の保全を考える。",
                instagram: "sophians2024_no.4",

                // TODO
                course: "",
                hobby: "",
                twitter: "",

                // コンテストに出場したきっかけ
                interview1: "鳥獣被害を減らすために、わな猟の資格を取得して活動しています。\n命を尊重した狩猟の新しい方法や取り組みを考え、多くの人々に知ってほしいと思ったからです。",
                // コンテストを通じて伝えたいこと
                interview2: "現在、鳥獣被害が拡大している一方で、猟友会は高齢化と人手不足の問題に直面しています。\nコンテストの発信を通じて、狩猟が抱える問題をみなさんに少しでも知って頂きたいです。",
                // アピールポイント
                interview3: "好奇心と人懐っこさ！！",
                // 所属サークル
                interview4: "乗馬サークル🐴",
                // アルバイト
                interview5: "巫女、集団塾講師、長期インターンシップ",
                // 上智大学の魅力
                interview6: "様々な価値観を持つ仲間ができ、施設も整っているので充実した学生生活を過ごすことができます。\n一番の魅力は、学食だけでなくキッチンカーなど「食」が充実していることです。",
                // マイブーム
                interview7: "名キャリを見ること",
                // 将来の夢
                interview8: "環境に携われたり、人に何かを与えられるお仕事に就きたいです。",
                // コンテストへの意気込み
                interview9: "実行委員会やファイナリストと共に、コンテストを盛り上げていきたいです。\n精一杯頑張りますので、応援よろしくお願い致します。"
            },
            {
                id: 5,
                entry_id: 5,
                block: 'A',
                stage: '1',
                aspect: 'landscape',
                name: '宮﨑 祐樹',
                birthday: "2001/10/31",
                faculty: "総合人間科学部\n社会学科",
                grade: "4年",
                hometown: "日本全国(転勤族）",
                theme: "日本人の幸福度UP!!",
                instagram: "sophians2024_no.5",

                // TODO
                course: "",
                hobby: "",
                twitter: "",

                // コンテストに出場したきっかけ
                interview1: "インフルエンサー活動に興味があり、学生生活最後、何かに挑戦したく、ソフィアンズコンテストに出場した。",
                // コンテストを通じて伝えたいこと
                interview2: "精神的豊かさを醸成すべく、SNSの発信を通じて、1人でも多くの人の「好き」を増やせたらと思います！",
                // アピールポイント
                interview3: "鬼のポジティブ、行動力、無敵の笑顔",
                // 所属サークル
                interview4: "体育会サッカー部（引退）",
                // アルバイト
                interview5: "エニタイムフィットネス",
                // 上智大学の魅力
                interview6: "目標に向かって努力する人が多いところ",
                // マイブーム
                interview7: "筋トレ",
                // 将来の夢
                interview8: "かっこいいお父さん",
                // コンテストへの意気込み
                interview9: "持ち前の「ポジティブ」を活かし、ファイナリスト5人・実行委員の皆さんでコンテストを盛り上げます!\n応援のほど宜しくお願いいたします!!"
            },
        ];
    },
});
